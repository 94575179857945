<template>
  <article class="green-city">
    <Hero
      heading="Electrify America's Green City"
      subheading="Accelerating freight and transit electrification in
          Long Beach and the Wilmington neighborhood of Los Angeles"
    >
      <template #image>
        <img src="@/assets/images/Green-City/hero@2x.png" alt="View of green city shipping containers" />
      </template>
      <h2>The next frontier of electrification</h2>
      <p>
        Electrify America’s Green City program is investing $25 million in Long Beach and the Wilmington neighborhood of
        Los Angeles to install Ultra-Fast EV charging infrastructure. This investment will support the electrification
        of public transit and freight trucks serving the community.
      </p>
      <a href="#contact-form"><div class="button">Contact Us</div></a>
    </Hero>
    <div class="l-one__col l-one__col--wide l-one__col--no-side-padding">
      <Card heading="Investing in the future" headlineLevel="h2" isReverse>
        <template #image>
          <img
            loading="lazy"
            src="@/assets/images/Green-City/future-one@2x.png"
            alt="Loading dock with shipping containers on ships"
          />
        </template>
        <p class="title">Creating a Green City</p>
        <p>
          The Green City program focuses on projects with strong potential to have lasting positive impacts on the local
          community. Areas identified for improvement include transit buses and medium- and heavy-duty freight truck
          fleets. Our investments will take the form of charging hardware, utility infrastructure, and associated
          project management costs – such as site surveying & design, construction, and commissioning.
        </p>
      </Card>
      <Card :isReverse="isPhone">
        <template #image>
          <img
            loading="lazy"
            src="@/assets/images/Green-City/future-two@2x.png"
            alt="Aerial view of a highway with 18-wheel tractor-trailers transporting shipping containers"
          />
        </template>
        <p class="title">Help transform EV fleet possibilities into realities</p>
        <p>
          Local engagement is needed to provide access to depots or other locations for charging stations, vehicle
          procurement, and funding for ongoing operational expenses.
        </p>
        <p><strong>Electrify America is seeking collaborators in the following areas:</strong></p>
        <DecorativeList>
          <li><p>Transit depot charging</p></li>
          <li><p>Enroute transit charging</p></li>
          <li>
            <p>Semi-public & private medium- and heavy-duty depots</p>
          </li>
        </DecorativeList>
      </Card>
    </div>
    <div id="contact-form" class="l-one--has-bg l-one--padding-bottom">
      <div class="l-one__col l-one__col--wide">
        <div class="form-title">
          <h2>Looking to collaborate?</h2>
          <p>If you’re in the process of electrifying your company’s fleet, we want to hear from you.</p>
        </div>
        <form class="form" @submit.prevent="validate" ref="form">
          <p aria-hidden="true" class="form-legend">An asterisk (<span>*</span>) indicates a required field</p>
          <p class="california">
            Will your project serve the City of Long Beach or the Wilmington <br />
            neighborhood of Los Angeles? <span class="require-mark" aria-hidden="true">*</span>
          </p>
          <section>
            <label class="radio">
              <input type="radio" name="cali" v-model="inCali" value="Yes" required />
              Yes
            </label>
            <label class="radio">
              <input type="radio" name="cali" v-model="inCali" value="No" />
              No
            </label>
          </section>
          <p v-if="inCali === 'No'">
            Thank you for your interest! Projects not serving the City of Long Beach or the Wilmington neighborhood of
            Los Angeles can be submitted by
            <a class="link" href="https://electrify-commercial.com/contact-us/" rel="noopener" target="_blank"
              >clicking here</a
            >.
          </p>
          <div class="inline-inputs">
            <div class="group-one">
              <label for="first_name"
                >First Name <span class="require-mark" aria-hidden="true">*</span>
                <input
                  id="first_name"
                  maxlength="55"
                  name="first_name"
                  pattern="^[a-zA-Z0-9\s]+(?:[a-zA-Z0-9-\s])+(?:[a-zA-Z0-9\s])$"
                  type="text"
                  v-model="answers['SuppliedFirstName']"
                  required
                />
              </label>
              <label for="last_name"
                >Last Name <span class="require-mark" aria-hidden="true">*</span>
                <input
                  id="last_name"
                  maxlength="55"
                  name="last_name"
                  required
                  pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
                  title="This field only accepts letters and special characters (no trailing spaces)"
                  type="text"
                  v-model="answers['SuppliedLastName']"
                />
              </label>
            </div>
            <div class="group-two">
              <label for="company-name"
                >Company Name <span class="require-mark" aria-hidden="true">*</span>
                <input
                  id="company-name"
                  name="companyName"
                  type="text"
                  v-model="answers['SuppliedCompanyName']"
                  required
                />
              </label>
              <label for="phone"
                >Business Phone <span class="require-mark" aria-hidden="true">*</span>
                <input
                  id="phone"
                  maxlength="10"
                  name="phone"
                  type="text"
                  pattern="^\d+$"
                  title="This field only accepts numbers"
                  v-model="answers['SuppliedPhone']"
                  required
                />
              </label>
            </div>
          </div>
          <label ref="email" for="email"
            >Business Email <span class="require-mark" aria-hidden="true">*</span>
            <input
              id="email"
              maxlength="55"
              name="email"
              required
              class="email"
              type="text"
              pattern="[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?:\.[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*@(?:[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_](?:[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_-]*[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
              title="Please enter the correct email address"
              v-model="answers['SuppliedEmail']"
            />
          </label>
          <label for="projectType">
            Project Type <span class="require-mark" aria-hidden="true">*</span>
            <select title="projectType" required v-model="answers['UseCase']">
              <option value disabled hidden>Select a response</option>
              <option value="Bus Depot">Bus Depot</option>
              <option value="Private Freight Depot">Private Freight Depot</option>
              <option value="Semi-Public Freight Depot">Semi-Public Freight Depot</option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label for="status"
            >What is the procurement status of your electric vehicle fleet?
            <span class="require-mark" aria-hidden="true">*</span>
            <select title="status" v-model="answers['EVFleetprocsection']" required>
              <option value disabled hidden>Select a response</option>
              <option value="Order Placed">Order Placed</option>
              <option value="Planning">Planning</option>
              <option value="N/A">N/A</option>
            </select>
          </label>
          <label for="size"
            >What is the size of your existing fleet serving the City of Long Beach and the Wilmington neighborhood of
            Los Angeles? <span class="require-mark" aria-hidden="true">*</span>
            <select title="size" v-model="answers['FleetSize']" required>
              <option value disabled hidden>Select a response</option>
              <option value="<5">&lt;5</option>
              <option value="5-20">5-20</option>
              <option value="20+">20+</option>
              <option value="N/A">N/A</option>
            </select>
          </label>
          <label for="notes">
            Notes
            <textarea name="notes" id="notes" cols="30" rows="10" v-model="answers['Notes']" />
          </label>
          <div>
            <input
              ref="submitButton"
              class="button button--submit"
              name="submit"
              type="submit"
              value="Submit"
              style="width: 200px"
            />
          </div>
          <p v-show="serverError !== ''" class="server-error">
            Error:
            <br />
            {{ serverError }}
          </p>
        </form>
      </div>
    </div>
  </article>
</template>

<script>
import ApiService from '@/services/Api';
import Hero from '@/components/Hero/Hero.vue';
import Card from '@/components/Card/Card.vue';
import DecorativeList from '@/components/DecorativeList/DecorativeList.vue';

export default {
  name: 'GreenCity',
  metaInfo: {
    title: 'Green City | Electrify America',
    meta: [
      {
        name: 'description',
        content: `Electrify America's Green City.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/green-city/' }],
  },
  components: { Hero, Card, DecorativeList },
  data() {
    return {
      inCali: '',
      answers: {
        SuppliedFirstName: '',
        SuppliedLastName: '',
        SuppliedCompanyName: '',
        SuppliedEmail: '',
        SuppliedPhone: '',
        EVFleetprocsection: '',
        FleetSize: '',
        UseCase: '',
        Notes: '',
      },
      serverError: '',
    };
  },
  methods: {
    async validate(e) {
      e.preventDefault();
      this.serverError = '';

      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: this.answers,
        };
        this.putGreenCities(params);
      } catch (error) {
        console.error(error);
      }
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
    putGreenCities(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', params.body.SuppliedEmail);
      ApiService.init();
      ApiService.put('/v1/salesforce/greencities', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'email-verification-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = 'There was an error submitting the form, please try again';
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = 'There was an error submitting the form, please try again';

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
  watch: {
    serverError(newValue) {
      if (newValue !== '') {
        if (newValue.includes('Email')) {
          this.$refs.email.style.color = '#d90041';
          this.$refs.email.scrollIntoView();
        } else if (newValue.includes('Serial')) {
          this.$refs.serial.style.color = '#d90041';
          this.$refs.serial.scrollIntoView();
        }
      }
    },
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  width: 90%;
  .california + section {
    display: flex;
    input {
      margin-bottom: 0;
      margin-right: 4px;
    }
    .radio:first-of-type {
      margin-right: 22px;
    }
  }
  .inline-inputs {
    margin-top: 40px;
    .group-one,
    .group-two {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }
    }
  }
}

.form-title {
  padding-top: 100px;
  h2 {
    margin-bottom: 20px;
  }
}
.title {
  font-size: 24px;
  margin-bottom: 0;
}
</style>
